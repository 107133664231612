<template>
  <CContainer>
    <CForm @submit.stop.prevent="SendFeedback()">
      <CCard>
        <CCardHeader>
          <h5 class="font-weight-bold mb-0">{{ $t('Navigation.Message/Feedback') }}</h5>
        </CCardHeader>
        <CCardBody>
          <CInput
            :label="$t('Personal.Profile/Account')"
            :value="$store.state.user.permission.Account"
            horizontal
            plaintext
          />
          <CSelect horizontal :label="$t('Feedback.Type')" :options="Options" v-model="Feedback.Type" :value.sync="Feedback.Type" :placeholder="$t('Global.PleaseSelect')"/>
          <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label">
              {{ $t('Feedback.Content')  }}
            </CCol>
            <CCol sm="9">
              <Editor api-key="no-api-key" tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.9/tinymce.min.js" :init="TinyMCE" v-model="Feedback.Content" />
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter class="text-center">
          <CButton type="submit" color="success" class="px-4">
            <CIcon name="cil-check-circle"/>
            {{ $t('Global.Submit') }}
          </CButton>
        </CCardFooter>
      </CCard>
    </CForm>
    <MediaStore :Config="MediaStoreConfig" ref="MediaStore" @OK="SetImages(ChooseImageType)"></MediaStore>
    <input id="tinymce-image-input" type="text" class="d-none">
  </CContainer>
</template>

<route>
{
"meta": {
"label": "意見回饋"
}
}
</route>

<script>
import Editor from '@tinymce/tinymce-vue'
import TinyMCESetting from '@/plugins/tinymce'

export default {
  name: 'Dashboard',
  layout: 'manage',
  components: {
    MediaStore: () => import('@/components/public/Media'),
    Editor
  },
  data() {
    return {
      Submit: false,
      Feedback: {},
      Options: [
        '錯誤回報', '使用建議', '功能需求', 'API串接服務', '合作提案', '其他'
      ],
      ChooseImageType: '',
      ChooseImageIndex: false,
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false
      },
      TinyMCE: {
        ...TinyMCESetting,
        file_picker_callback: (callback) => {
          this.OpenMediaStore('Editor', false)
          const input = document.getElementById('tinymce-image-input')
          input.addEventListener('change', (event) => {
            callback(event.target.value, {title: ''})
          })
        },
      },
    }
  },
  mounted() {
  },
  methods: {
    OpenMediaStore(type = 'Carousel', multiple = true, index = false) {
      this.ChooseImageType = type
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = multiple
      if (index !== false) {
        this.ChooseImageIndex = index
      }
    },
    async SetImages (type = 'Carousel') {
      switch (type) {
        case 'Editor':
          document.getElementById('tinymce-image-input').value = await this.ResolveImageURL()
          document.getElementById('tinymce-image-input').dispatchEvent(new Event('change'))
          break
      }
    },
    ResolveImageURL () {
      if (this.$store.state.user.permission.StaticDomain) {
        return Promise.resolve(this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
      }
      return this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
        return URL
      })
    },
    SendFeedback() {
      this.$Progress.start()
      return this.$store.dispatch('InnerRequest', {
        url: '/system/feedback',
        method: 'post',
        data: this.Feedback
      }).then(() => {
        this.Feedback = {}
        this.$Progress.finish()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Feedback.SuccessMessage'),
          type: 'success',
          duration: 6000
        })
      }).catch((error) => {
        console.log(error)
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + error.msg,
          type: 'error'
        })
      })
    }
  }
}
</script>
